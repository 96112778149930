import React, { useContext, useState } from "react";
import { Grid, Dialog, DialogContent, IconButton, DialogTitle } from "@mui/material";
import SmartproductAdd from "./SmartproductAdd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import SmartOffer from "./SmartOffer";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import DataContext from "../../../../context/DataContext";
import CloseIcon from "@mui/icons-material/Close";

const SmartProductList = () => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext)
  const { id } = useParams()
  let ids
  if (id !== null) {
    ids = atob(id)
  }
  const [opened, setOPened] = useState();
  const [offerOpened, setOfferOpened] = useState();
  const [reasonOpen, setReasonOpen] = useState(false)
  const [privilegesData, setPrivilegesData] = useState([])
  useDidMountEffect(() => {
    getSmartCardApi()
  }, [])
  const getSmartCardApi = () => {
    apiService(`smartcard/get?id=${ids}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data) {
          if (result.data.privileges) {
            setPrivilegesData(result.data.privileges)
          }
        }
      }
    }).catch((err) => {

    });
  }
  const handleReasonOpen = () => {
    setReasonOpen(true)
  }
  const handleReasonClose = () => {
    setReasonOpen(false)
  }
  const handleOpened = () => {
    setOPened(true);
  };

  const handleClosed = () => {
    setOPened(false);
  };


  const handleOfferOpened = () => {
    setOfferOpened(true);
  };

  const handleOfferClosed = () => {
    setOfferOpened(false);
  };
  const data = [
    {
      id: 1,
      product: "Lorem Ipsum",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      marketPrice: 999,
      smartCardPrice: 799,
      deploymentCost: 199,
    },
    {
      id: 2,
      product: "Lorem Ipsum",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      marketPrice: 999,
      smartCardPrice: 999,
      deploymentCost: 199,
    },
    {
      id: 3,
      product: "Lorem Ipsum",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
      marketPrice: 999,
      smartCardPrice: 999,
      deploymentCost: 199,
    },
  ];
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12}>
          <div className="profile-box2">
            <div className="d-flex align-items-center">
              <div className="">
                <h5>Add Product / Services </h5>
                <p className="para">
                  Provide details about the services or products you offer,
                  including pricing, terms, and any special offers for our
                  members.
                </p>
              </div>
              <div className="modal-smcrd-service">
                <button
                  className="product-button"
                  sx={{ textTransform: "none" }}
                  size="small"
                  onClick={handleOpened}
                >
                  Add Product / Service
                </button>
                {/* <button
                  className="product-button mt-3"
                  sx={{ textTransform: "none" }}
                  size="small"
                  onClick={handleOfferOpened}
                >
                  View Offer
                </button> */}
              </div>
            </div>

          </div>
          {
            privilegesData.length > 0 ? (
              <div className="profile-box2 mt-3">
                <div className="text-center mb-2">
                  <h5>Privileges</h5>
                </div>
                <div className="smcrd-prof-tables">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead sx={{ background: '#EDF1FF' }}>
                        <TableRow>
                          <TableCell>S.No</TableCell>
                          <TableCell>Product / Service</TableCell>
                          <TableCell >Market Price / Month</TableCell>
                          <TableCell >Smart card Price</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {privilegesData &&
                          privilegesData.map((row, index) => (
                            <TableRow key={row.id}>
                              <TableCell>
                                <Typography variant="h6">
                                  {/* {`0${row.id}.`} */}
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <h5>
                                  <span className="prod-name">{row.name ? row.name : '-'}</span>
                                </h5>
                                <p className="table-desc"> {row.description}</p>
                              </TableCell>
                              <TableCell >
                                <h6 className="prod-name">₹{row.marketPrice}</h6>
                                <p className="table-para-sm">
                                  {row.addChargeDesc}:
                                  <span className="prod-name mt-1 font-price">
                                    ₹{row.addChargeMarketPrice}
                                  </span>
                                </p>
                              </TableCell>
                              <TableCell >
                                <h6 className="prod-name">₹{row.smartCardPrice}</h6>
                                <p className="table-para-sm">
                                  {row.addChargeDesc}:
                                  <span className="prod-name mt-1 font-price">
                                    ₹{row.addChargeSmartCardPrice}
                                  </span>
                                </p>
                              </TableCell >
                              <TableCell>
                                <div>
                                  {(row.status === 0) && (
                                    <p className="list-6-clr-smcr">Requested</p>

                                  )}
                                  {(row.status === 1) && (
                                    <>
                                      <p className="list-5-clr-smcr">Rejected</p>
                                      <div className="status-smcards">
                                      <span onClick={handleReasonOpen}>Click to view</span><span> Reason</span>
                                    </div>
                                    </>
                                  )}
                                  {(row.status === 2) && (
                                    <p className="list-5-clr-smc ">Approved</p>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ) : (
              <div className="profile-box3 mt-2">
                <div className="text-center">
                  <h5 style={{ fontWeight: "600" }}>Privileges</h5>
                </div>
              </div>
            )
          }
        </Grid>
      </Grid>
      <Dialog
        open={opened}
        // onClose={handleClosed}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            p: 2,
            borderRadius: 3,
          },
        }}
      >
        <SmartproductAdd open={opened} onClose={handleClosed} getSmartCardApi={getSmartCardApi} />
      </Dialog>
      <Dialog
        open={reasonOpen}
        onClose={handleReasonClose}
        maxWidth="xs"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            p: 2,
            borderRadius: 3,
          },
        }}
      >
        <DialogTitle>
        Rejection Reason
        <IconButton
          aria-label="close"
          onClick={handleReasonClose}
          sx={{
            position: "absolute",
            right: 44,
            top: 30,
            backgroundColor: "red",
            borderRadius: "50%",
            color: "white",
            padding: "1px",
            '&:hover':{
              backgroundColor: "red",
              color: "white",
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent>
          <div className="text-center mt-2">
            <h6>Offer High</h6>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SmartProductList;
