
import React, { useContext, useState } from 'react'
import DataContext from '../../../../context/DataContext';
import ProfileCheckDialog from '../Tn_fest/Ticketbookdialog/ProfileCheckDialog';
import Delegatefreepass from '../Tn_fest/PassDialog/Delegatefreepass';
import { Dialog } from '@mui/material';
import Thiruvizha24Modal from './Thiruvizha24Modal';
import cryptodecryptJS from '../../../../hooks/cryptodecryptJS';
import FoodModal from './FoodModal';

const Thiruvizha24 = () => {
    var personaName = localStorage.getItem("type");
    const { navigator, setProfileDialog } = useContext(DataContext);
    const [profileDialogBox, setProfileDialogBox] = useState(false)

  
    const handleConference = () => {
        localStorage.setItem('way', 'Conference');
        setProfileDialogBox(true)
    };
    var token = localStorage.getItem("jwttoken");
    var email = localStorage.getItem("email");
    var personaName = localStorage.getItem("type");
    var profileupdated = localStorage.getItem("profile")
    const handleExhibitor = () => {
        if (!token) {
            setProfileDialog(false);
            // const currentPath = window.location.pathname;
            // localStorage.setItem('currentPath', currentPath);
            localStorage.setItem('way', 'Exhibitor');
            const currentPath = window.location.pathname;
            localStorage.setItem('currentPath', currentPath);
            navigator('/login');
            return;
        }
        if (token && email && profileupdated === "true") {
            var conferenceurl = `https://event.startuptn.in/authorize/login?email=${cryptodecryptJS(email)}`
            localStorage.setItem("conferencePath", conferenceurl);
            window.location.href = conferenceurl;
        } else {
            var type = localStorage.getItem('type')
            if (type === 'NA') {
                var id = Number(localStorage.getItem('userId'))
                navigator(`letsstart/${btoa(id)}`)
                setProfileDialog(false);
            } else {
                setProfileDialog(true);
            }

        }
    };
    const handleFastPage = () => {
        navigator('/fest');
    }
    const handleProfileClose = () => {
        setProfileDialogBox(false)
    }
    return (
        <div className="banner-slide c-pointer" >
            <div className="banner-div">
                <img src='/images/Banner/Thiruvizha Web Banner (5).png' className="banner-imgss" alt='' onClick={() => handleFastPage()} />
                <div className='act-btns11-food '>
                    <button onClick={() => handleConference()} className="publish-button1">
                        Book for Conference / Visitors
                    </button>
                    <button onClick={() => handleExhibitor()} className="publish-button2">
                        Book As Exhibitor
                    </button>
                    <FoodModal/>
                </div>
            </div>
            <Dialog fullWidth={true} maxWidth={"md"} open={profileDialogBox}>
                <Thiruvizha24Modal handleProfileClose={handleProfileClose} />
            </Dialog>
            <div>
                <ProfileCheckDialog personaName={personaName} />
            </div>
            
        </div>
    )
}

export default Thiruvizha24