import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Tanfund = ({ name }) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const [tanseedSwipers, setTanseedSwipers] = useState(1);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [eventList, setEventList] = useState([]);
  const navigate = useNavigate();
  useDidMountEffect(() => {
    getServiceApi();
    dashboardApi();
  }, []);
  const getServiceApi = () => {
    var req = {
      listSize: 25,
      pageNumber: 1,
    };
    axios.post(`${process.env.REACT_APP_API_URL_TANFUND}admin/investor/profile/eco/list`, req)
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.startupFundReceived) {
            setServiceData(result.data.startupFundReceived);
          }
        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId, startDate, endDate]);
  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    var req = {
      searchString: searchInputValue,
      categoryId: categoryId,
      listSize: 12,
      pageNumber: page,
      fromDate: startDate,
      toDate: endDate,
      serviceId: 1
    };
    apiService(`event/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.event) {
            setEventList(result.data.event);
            setTotalPage(result.data.totalPages);
          }
        }
      })
      .catch((err) => { });
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handlenow = () => {
    navigate('/login')
  }
  useEffect(() => {
    size();
    tanseedswiper();
  }, []);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  const tanseedswiper = () => {
    if (window.innerWidth > 1440) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setTanseedSwipers(1);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setTanseedSwipers(1);
    } else {
      setTanseedSwipers(1);
    }
  };
  const cardItems = [
    {
      cardimg: "/images/holding-hands.png",
      cardTitle: "Venture Capitalists",
      cardDetails:
        "Professional entities managing pooled investor funds to invest in Startups, offering capital, expertise, and networks.",
    },
    {
      cardimg: "/images/Profile/priv-equ.png",
      cardTitle: "Private Equity",
      cardDetails:
        "Invests in mature companies, often taking controlling interests. Focuses on scaling and optimizing businesses.",
    },
    {
      cardimg: "/images/Profile/venture-debt.png",
      cardTitle: "Venture Debt",
      cardDetails:
        "Debt financing for venture-backed startups lacking traditional collateral, complementing equity financing.",
    },
    {
      cardimg: "/images/Profile/Family-ofc.png",
      cardTitle: "Family Offices",
      cardDetails:
        "Manages wealth of high-net-worth families and making private equity investments with a long-term horizon.",
    },
    {
      cardimg: "/images/Profile/revenue-.png",
      cardTitle: "Revenue-based Financing",
      cardDetails:
        "Startups receive capital in exchange for a percentage of ongoing gross revenues. Repayment is tied to revenue performance.",
    },
    {
      cardimg: "/images/Profile/angel-net.svg",
      cardTitle: "Angel Networks",
      cardDetails:
        "Groups of angel investors pooling resources to co-invest in Startups, sharing deal flow and due diligence.",
    },
    {
      cardimg: "/images/Profile/govt-inv.png ",
      cardTitle: "Government Investment Agencies",
      cardDetails:
        "Support Startups with funding, mentorship and networks for economic growth, innovation and employment creation.",
    },
    {
      cardimg: "/images/Profile/Accelrator.png",
      cardTitle: "Accelerator",
      cardDetails:
        "Structured programs offering Startups seed investment, mentorship, and resources in return for equity",
    },
  ];
  $(document).ready(function () {
    function animateElements() {
      $("#showDiv").hide();
      $("#contentDiv").show();
      $("#Num1").show();
      $("#Num2").hide();
      $("#Num3").hide();
      $("#Num4").hide();
      $("#Num5").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $("#move1").addClass("activeShow");
      $("#move2").removeClass("activeShow");
      $("#move3").removeClass("activeShow");
      $("#move4").removeClass("activeShow");
      $("#move5").removeClass("activeShow");
    }
    function animateElements1() {
      $("#showDiv1").hide();
      $("#contentDiv1").show();
      $("#iconDiv1").show();
      $("#Num2").show();
      $("#Num1").hide();
      $("#Num3").hide();
      $("#Num4").hide();
      $("#Num5").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $("#move1").removeClass("activeShow");
      $("#move2").addClass("activeShow");
      $("#move3").removeClass("activeShow");
      $("#move4").removeClass("activeShow");
      $("#move5").removeClass("activeShow");
    }
    function animateElements2() {
      $("#showDiv2").hide();
      $("#contentDiv2").show();
      $("#iconDiv2").show();
      $("#Num3").show();
      $("#Num1").hide();
      $("#Num2").hide();
      $("#Num4").hide();
      $("#Num5").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $("#move1").removeClass("activeShow");
      $("#move2").removeClass("activeShow");
      $("#move3").addClass("activeShow");
      $("#move4").removeClass("activeShow");
      $("#move5").removeClass("activeShow");
    }
    function animateElements3() {
      $("#showDiv3").hide();
      $("#contentDiv3").show();
      $("#iconDiv3").show();
      $("#Num4").show();
      $("#Num1").hide();
      $("#Num2").hide();
      $("#Num3").hide();
      $("#Num5").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv4").show();
      $("#contentDiv4").hide();
      $("#move1").removeClass("activeShow");
      $("#move2").removeClass("activeShow");
      $("#move3").removeClass("activeShow");
      $("#move4").addClass("activeShow");
      $("#move5").removeClass("activeShow");
    }
    function animateElements4() {
      $("#showDiv4").hide();
      $("#contentDiv4").show();
      $("#iconDiv4").show();
      $("#Num5").show();
      $("#Num1").hide();
      $("#Num2").hide();
      $("#Num3").hide();
      $("#Num4").hide();
      $("#showDiv").show();
      $("#contentDiv").hide();
      $("#showDiv1").show();
      $("#contentDiv1").hide();
      $("#showDiv2").show();
      $("#contentDiv2").hide();
      $("#showDiv3").show();
      $("#contentDiv3").hide();
      $("#move1").removeClass("activeShow");
      $("#move2").removeClass("activeShow");
      $("#move3").removeClass("activeShow");
      $("#move4").removeClass("activeShow");
      $("#move5").addClass("activeShow");
    }
    let animationCount = 0;
    setInterval(function () {
      switch (animationCount) {
        case 0:
          animateElements();
          break;
        case 1:
          animateElements1();
          break;
        case 2:
          animateElements2();
          break;
        case 3:
          animateElements3();
          break;
        case 4:
          animateElements4();
          animationCount = -1;
          break;
      }
      animationCount++;
    }, 3000);
  });
  const [dashboardData, setDashboardData] = useState({});
  const dashboardApi = () => {
    apiService("home/matrix", "", "unauthget")
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.response.responseStatus === "Success"
        ) {
          setDashboardData(result.data);
        }
      })
      .catch((err) => { });
  };
  let username = localStorage.getItem("name");
  let jwttoken = localStorage.getItem("jwttoken");
  let persona = localStorage.getItem("type");
  const handleSupportPage = () => {
    if (jwttoken) {
      if (persona === "ADMIN") {
        navigator("/admin/support/startup/raise");
      } else if (persona === "STARTUP") {
        navigator(`/startup/support/${username}/add`);
      } else {
      }
    } else {
      navigator("/login");
    }
  };
  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`Funding Ecosystem   >  TANFUND`}</span>
                <h1 className="banner-text-gradient">TANFUND</h1>
                <h6>
                  Empowering Innovation , Enabling Investment Bridging Tamil
                  Nadu Startups with Global Investors
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Apply for Funding</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage">
                <h4>What is TANFUND ?</h4>
                <p>
                  TANFUND is a premier fund aggregator promoted by StartupTN,
                  the Tamil Nadu Government’s nodal agency for Startup and
                  Innovation. This platform connects innovative and scalable
                  Startups across a wide spectrum of sectors with diverse
                  funding partners.
                </p>
                <div className="starup-num">
                  <div>
                    <h4>{dashboardData.recognisedStartups}</h4>
                    <p>Startups</p>
                  </div>
                  <div>
                    <h4>350+</h4>
                    <p>Investors</p>
                  </div>
                </div>
                <div className="left-content-actions">
                  <button className="ser-viewbtn">Apply for Funding</button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img" style={{ position: "relative" }}>
                <img
                  src="/images/datasmap3.png"
                  className="datasmap img-fluid"
                  alt=""
                />
                <p className="datasmap-dyn font-weight-bold">
                  {dashboardData.recognisedStartups}
                  <br />
                  <span className="datasmap-dyn-con font-weight-bold">
                    Startups in <br />
                    Tamil Nadu
                  </span>
                </p>
              </div>
            </Grid>
          </Grid>
          <div className="smartcrd-details-div mt-5">
            <div className="service-partner-overlay"></div>
            <div className="det-lines">
              <div className="smcrd-services">
                <h3>
                  Funding <span>Partners</span>
                </h3>
                <p>
                  Over 100+ Service Partners Onboarded Successfully – Join the
                  Revolution!"
                </p>
                <div className="partners-company-logos-scroll">
                  <div>
                    {serviceData && serviceData.map((logo, i) => (
                      <div className="partners-company-logos">
                      <div className="partners-company-sec1" key={i}>
                        <img
                          src={logo.logo}
                          alt={logo.logo}
                          className="part-logos1"
                        />
                        <div>
                         <span className="logo-texts">{logo.startupName}</span>
                        </div>
                      </div>
                      
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <section className="work_section">
          <div className="container">
            <div className="work_title">
              <h1 className="work_star">How It Works?</h1>
            </div>
            <div className="row work_row">
              <div className="col-xl-6 col-lg-6 col-12 circle-sm">
                <div className="circle-ani">
                  <div className="circle-line">
                    <center>
                      <div className="num activeShow" id="move1">
                        <div className="col">1</div>
                      </div>
                      <div className="num1" id="move2">
                        <div className="col">2</div>
                      </div>
                      <div className="num2" id="move3">
                        <div className="col">3</div>
                      </div>
                      <div className="num3" id="move4">
                        <div className="col">4</div>
                      </div>
                      <div className="num4" id="move5">
                        <div className="col">5</div>
                      </div>
                    </center>
                    <img
                      src="/images/file1.png"
                      className="icon img-fluid"
                      id="Num1"
                      alt=""
                    />
                    <img
                      src="/images/file1.png"
                      className="icon img-fluid"
                      id="Num2"
                      alt=""
                      style={{ display: "none" }}
                    />
                    <img
                      src="/images/file1.png"
                      className="icon img-fluid"
                      id="Num3"
                      alt=""
                      style={{ display: "none" }}
                    />
                    <img
                      src="/images/file1.png"
                      className="icon img-fluid"
                      id="Num4"
                      alt=""
                      style={{ display: "none" }}
                    />
                    <img
                      src="/images/file1.png"
                      className="icon img-fluid"
                      id="Num5"
                      alt=""
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-12 content-div">
                <div id="showDiv" style={{ display: "none" }}>
                  <div className="htw-info mt-4">
                    <img src="/images/Landing-Point.svg" className="" alt="" />
                    <h4>Submission of Application</h4>
                  </div>
                </div>
                <div id="contentDiv">
                  <span className="step">
                    <h5>Step 1</h5>
                  </span>
                  <div className="p-info">
                    <h4>Submission of Application</h4>
                    <p>
                      Startups introduce themselves by detailing their business,
                      team, product or service, market and specific funding
                      requirements.
                    </p>
                  </div>
                </div>
                <div className="heading2" id="showDiv1">
                  <div className="htw-info mt-4">
                    <img src="/images/Landing-Point.svg" className="" alt="" />
                    <h4>Allotment to Funding Partners</h4>
                  </div>
                </div>
                <div id="contentDiv1" style={{ display: "none" }}>
                  <span className="step">
                    <h5>Step 2</h5>
                  </span>
                  <div className="p-info">
                    <h4>Allotment to Funding Partners</h4>
                    <p>
                      Based on the funding ask and other relevant details, The
                      application is assigned to potential funding partners who
                      would be interested in the specific domain or stage of the
                      Startup.
                    </p>
                  </div>
                </div>
                <div id="showDiv2">
                  <div className="htw-info mt-4">
                    <img src="/images/Landing-Point.svg" className="" alt="" />
                    <h4>Evaluation Phase</h4>
                  </div>
                </div>
                <div id="contentDiv2" style={{ display: "none" }}>
                  <span className="step">
                    <h5>Step 3</h5>
                  </span>
                  <div className="p-info">
                    <h4>Evaluation Phase</h4>
                    <p>
                      The designated investment partner reviews the application,
                      may conduct preliminary meeting with the Startup or make a
                      decision based on the data provided.
                    </p>
                  </div>
                </div>
                <div id="showDiv3">
                  <div className="htw-info mt-4">
                    <img src="/images/Landing-Point.svg" className="" alt="" />
                    <h4>Due Diligence Round</h4>
                  </div>
                </div>
                <div id="contentDiv3" style={{ display: "none" }}>
                  <span className="step">
                    <h5>Step 4</h5>
                  </span>
                  <div className="p-info">
                    <h4>Due Diligence Round</h4>
                    <p>
                      The successful Startup moves thorough due diligence phase,
                      where the investment partner evaluates the financial
                      records, product viability, market potential, team
                      credentials and other pertinent details.
                    </p>
                  </div>
                </div>
                <div id="showDiv4">
                  <div className="htw-info mt-4">
                    <img src="/images/Landing-Point.svg" className="" alt="" />
                    <h4>Finalization of Investment Sheet</h4>
                  </div>
                </div>
                <div id="contentDiv4" style={{ display: "none" }}>
                  <span className="step">
                    <h5>Step 5</h5>
                  </span>
                  <div className="p-info">
                    <h4>Finalization of Investment Sheet</h4>
                    <p>
                      Once the due diligence is satisfactory, the Startup and
                      the investment partner discuss and finalise the terms of
                      investment - funding, equity share, milestones and other
                      related agreements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="why-smartcrd-sec mt-5">
          <div className="why-sm-title">
            <h3>
              Why <span>TANFUND?</span>
            </h3>
          </div>
          <div className="why-sm-paras">
            <Container>
              <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img src={"/images/access-key.png"} alt="Acess Key" />
                    </div>
                    <div className="why-sm-det">
                      <h6>Direct Access</h6>
                      <p className="">
                        Engage directly with a spectrum of reputed global
                        investors keen on investing in TN-based Startups.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img
                        src={"/images/hand-shake.png "}
                        alt="comprehensive data"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Tailored Matches</h6>
                      <p className="">
                        Benefit from funding avenues aligning with your
                        Startup's specific requirements and growth phase.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts1 text-center">
                    <div className="why-sm-icns">
                      <img
                        src={" /images/exclusive-event.png"}
                        alt="exclusive events"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Enhanced Visibility</h6>
                      <p className="">
                        Boost your presence and earn due recognition among
                        investor communities.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <section className="join-us-now-sec1">
          <Container>
            <div className="service-partners-title">
              <h4>List of Funding Partners</h4>
              <p>
                Here are the detailed steps of the TANFUND process for providing
                funding to startups.
              </p>
            </div>
            <div className="mt-5">
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                {cardItems &&
                  cardItems.map((a, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <div key={i}>
                        <div className="funding_details1">
                          <img
                            src={a.cardimg}
                            alt={a.cardTitle}
                            className="card-image mb-4"
                          />

                          <h5 className="cardtitle  mb-4">{a.cardTitle}</h5>
                          <p className="carddetail  mb-4">{a.cardDetails}</p>

                        </div>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Container>
        </section>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="service-partners-title">
                  <h4>Pride of Startups</h4>
                  <p>
                    Here are the detailed steps of the TANFUND process for
                    providing funding to startups.
                  </p>
                </div>
              </Grid>
            </Grid>
            <div className="service-crd">
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
                style={{ paddingBottom: "50px" }}
              >
               { serviceData && serviceData.map((item, i) => (
                  <SwiperSlide key={i}>
                    <Card className="sup-sm-crds1">
                      <div className="service-card-back-layers1">
                        <div className="layer-crd-back-img1">
                          <div className="service-card-back-profss1">
                            <img
                              src={item.logo}
                              alt="crd-img1"
                              className="service-card-back-image1"
                            />
                          </div>
                        </div>
                      </div>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          padding: "24px 0px 0px 0px",
                        }}
                      >
                        <p className="ser-crd-title-text">{item.startupName}</p>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
                <div className="ser-content-actions">
                  <button className="ser-viewbtn">
                    Explore all services partners
                  </button>
                </div>
              </Swiper>
            </div>
          </Container>
        </div>
        <div className="faq-section">
          <div className="faq-content">
            <Container>
              <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <div className="faq-box-div">
                    <h3>Frequently Asked Questions</h3>
                    <div className="accordion">
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div className="d-flex w-100">
                            <p>
                              How long does it take to complete a web
                              development project?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel1" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          The timeline varies depending on the project's
                          complexity and requirements. Our team strives to
                          deliver projects on time while maintaining the highest
                          quality standards.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <div className="d-flex  w-100">
                            <p>
                              Can you handle large-scale mobile app development
                              projects?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel2" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary>
                          <div className="d-flex  w-100 ">
                            <p>
                              Do you offer maintenance services for websites and
                              apps developed by other companies?
                            </p>
                            <div className="ml-auto">
                              {expanded === "panel3" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div className="tans-div">
                    <h3 className="eveing">Events</h3>
                    <Swiper
                      slidesPerView={tanseedSwipers}
                      spaceBetween={30}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      className="my-Swiper"
                    >
                      {eventList.length > 0 ? (
                        <>
                          {eventList &&
                            eventList.map((item, i) => (
                              <SwiperSlide key={i}>
                                <div className="faq-box-2-flex">
                                  <div className="faq-box2-img">
                                    <img
                                      src={item.imageName}
                                      alt="smartcard-img"
                                      className="tans-get"
                                    />
                                  </div>
                                  <div className="faq-box2-content">
                                    <h4>{item.title}</h4>
                                    <p> {item.formattedTime}</p>
                                    <div className="tan-content-actions">
                                      <button className="tan-viewbtn" onClick={handlenow}>
                                        Enroll Now
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                        </>
                      ) : (
                        <>
                          <p className="eve-no-fou">Events Not Found</p>
                        </>
                      )}
                    </Swiper>
                  </div>
                  <div className="set-support-div">
                    <div className="faq-support-content">
                      <div className="faq-support-img">
                        <img src={"/images/smcrd-person.png"} alt="person" />
                      </div>
                      <div className="faq-support-para">
                        <p>
                          Need support for a funding-related query? Don't worry,
                          we're here to help you!
                        </p>
                        <span className="support-links">
                          <p onClick={() => handleSupportPage()}>
                            Click here to raise a support ticket
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>Let’s Get Started</h3>
                    <p>
                      TANFUND provides access to a vibrant community of
                      entrepreneurs, investors, mentors, and industry partners.
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default Tanfund;
