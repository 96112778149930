import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions, DialogTitle, Grid, IconButton } from '@mui/material';

import CloseIcon from "@mui/icons-material/Close";


const FoodModal = () => {
    const [fullWidth, setFullWidth] = useState(true);
    const [openFood, setOpenFood] = React.useState(false);


    const handleFoodModalOpen = () => {
        setOpenFood(true);
    };

    const handleFoodModalClose = () => {
        setOpenFood(false);
    };

    const handleRedirectFood = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdd6hrmAsCr9fyTEFZH_RL3mrK0jUH4pJnuv5ykQT_X3dQgZA/viewform', '_blank','noopener,noreferrer')
    }
    return (
        <div>
            <button onClick={() => handleFoodModalOpen()} className="publish-button1">
                Food Startups Corridor
            </button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={"lg"}
                open={openFood}
                onClose={handleFoodModalClose}
                // sx={{
                //     '& .MuiDialog-paper': {
                //         height: '120vh', 
                //     },
                    
                // }}
            >
                {/* <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleFoodModalClose}
                    sx={{
                        position: "absolute",
                        right: 44,
                        top: 30,
                        backgroundColor: "red",
                        borderRadius: "50%",
                        color: "white",
                        padding: "1px",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle> */}
                <DialogContent sx={{padding:'10px'}}>
                   <div className='Dialog-color'>
                    <div className='text-center'>
                    <div>
                    <img src={'/images/thiru_modal/logo-white.webp'} alt="logo" className='start-tir-foodmod-img'/>
                    </div>
                    <div className='mt-2'>
                        <h2 className="title-modals "><span style={{color: '#efaf26'}}>Food Startups </span>Corridor</h2 >
                        <p className='my-2' style={{color:'#fff'}}>Tamil Nadu Startup Thiruvizha 2024</p>
                    </div>
                    </div>
                    <div className='main-grid-food mt-4' >
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3} className="grid-container1">
                        <Grid item xs={12} sm={12} md={6} lg={6} className="grid-item1 text-center">
                            <img src={'/images/thiru_modal/Corrideor 2.jpg'} alt="tray-imag" className='tray_imag'/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="grid-item1 mb-1">
                            <div className='right-cont-food mb-3'>
                            <p className='paras-food-mod'>We are launching the Food Startups Corridor at Tamil Nadu Startup Thiruvizha 2024 in Madurai on September 28 & 29, 2024. A unique chance to showcase your innovative food products. </p>
                            <p className='paras-food-mod'> With 100 slots available, StartupTN offers<span className='font-food-bold'> space, conference pass, and lunch </span>for&nbsp;<span className='font-food-bold'>Rs. 2500</span>&nbsp;(inclusive of GST).</p>

                            <h6><span style={{color:'#f8e01b'}}>Payment is required only after product approval.</span></h6>
                            </div>
                            </Grid>
                    </Grid>
                    </div>
                    <div className='text-center my-2  pb-1'>
                            <button onClick={() => handleRedirectFood()} className="publish-button-food1">
                                Register Now
                            </button>
                        </div>
                   </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default FoodModal
