import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { Button, Grid, List } from "@mui/material";
import { NavLink, Outlet, useParams } from "react-router-dom";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { useState } from "react";
import { useContext } from "react";
import DataContext from "../../../context/DataContext";
import { FaArrowLeft } from "react-icons/fa6";
const SmartProfileLayout = () => {
  const { navigator, setPageLoading, validateEmail, getApi, userProfileId, formData } = useContext(DataContext)
  const { id } = useParams()
  let smartCardId
  if (id !== null) {
    smartCardId = atob(id)
  }
  let smartCardKey = localStorage.getItem('smartCard')
  useDidMountEffect(() => {
    getApi();
  }, [userProfileId]);
  useDidMountEffect(() => {
    getSmartCardApi()
  }, [])

  const [smartCardData, setSmartCardData] = useState([])
  const getSmartCardApi = () => {
    apiService(`smartcard/get?id=${smartCardId}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data) {
          setSmartCardData(result.data);

        }
      }
    }).catch((err) => {

    });
  }
  const handleBackBtn = () => {
    localStorage.removeItem('smartCard')
    navigator('/smartcard/service')
  }
  const [showHighlights, setshowHighlights] = useState(true)
  const handleShowHighlights = (value) => {
    setshowHighlights(value)
  }
  return (
    <div className="backgrnd-color-service-prof">
      <div className="container-fluid flu-class">
        <div className="smartcard-prof-imgs">
          <div className="ml-5">
            <Button className="back-btns" onClick={handleBackBtn}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </Button>
          </div>
        </div>
        <div className="user-profile">
          <div className="user-boxs">
            <div className="first-box">
              <div className="all-userdetails1">
                <div className="d-flex align-items-center">
                  <div>
                    {/* <img
                      className="user-brandlogo img-fluid"
                      // src={userData.companyLogoURL ? userData.companyLogoURL : '/images/user.png'}
                      src={formData.imageName}
                      alt="logo"
                    /> */}
                     <img
                        className="user-brandlogo img-fluid"
                        src={
                          formData.imageName
                            ? formData.imageName
                            : "/images/user.png"
                        }
                        alt="logo"
                      />
                  </div>
                  <div className="brand-details ml-3">
                    <h4 className="font-weight-bold">
                      {formData.name}
                      <span>
                        <img src={"/images/verified-tick.png"} alt="verified" />
                      </span>
                    </h4>
                    <p className="para mb-0">
                      <span>
                        {formData.sector}
                        {/* <span style={{ color: "gray" }}>& More</span> */}
                      </span>
                    </p>
                    <span style={{ fontSize: "12px", color: "gray" }}>
                      {formData.state}, {formData.country}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="mr-2">
                      <span style={{ fontSize: "10px" }}>
                        Engagement Level :
                        <span
                          style={{
                            color: "#020671",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          ACE
                        </span>
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "10px" }}>
                        Member Since :
                        <span
                          style={{
                            color: "#020671",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          {formData.memberSince}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="det-flex mt-3">
                    <div className="mobile-resp">
                      <div>
                        <FaPhoneAlt
                          className="ml-2"
                          style={{
                            color: "#020671",
                          }}
                        />
                      </div>
                      <div>
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          +91 {formData.phone}
                        </span>
                      </div>
                    </div>
                    <div className="mobile-resp">
                      <div>
                        <MdOutlineMail
                          className="ml-2"
                          style={{
                            color: "#020671",
                          }}
                        />
                      </div>
                      <div>
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          {formData.email}
                        </span>
                      </div>
                    </div>
                    <div className="mobile-resp">
                      <div>
                        <CiGlobe
                          className="ml-2"
                          style={{
                            color: "#020671",
                          }}
                        />
                      </div>

                      <div>
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          {formData.website}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="profile-btn  mt-3">
                    <div className="mr-3 edit-btn-res">
                      <Button
                        variant="outlined"
                        className="edit-btn-profile"
                        sx={{
                          textTransform: "none",
                          border: "1px solid",
                          borderColor: "#020671",
                          color: "#020671",
                          padding: "6px 20px",
                          background: "",
                        }}
                      >
                        Edit Profile
                      </Button>
                    </div>
                    <div className="mr-3 edit-btn-res">
                      <Button
                        variant="contained"
                        className="edit-btn-profile"
                        sx={{
                          textTransform: "none",
                          background: "#0B40AA",
                          padding: "6px 20px",
                        }}
                      >
                        Join as Service Partner
                      </Button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="profile-box-filter">
                <List
                  style={{
                    border: "1px solid gray",
                    padding: "0px",
                    display: "grid",
                  }}
                  className="Lists-prof-det"
                >
                  <NavLink
                    to={`/smartcard/${id}/profile`}
                    className={`custom-button`}
                    onClick={()=>handleShowHighlights(true)}
                  >
                    Profile
                  </NavLink>
                  {
                    smartCardKey === 'join' && (
                      <>
                        <NavLink
                          to={`/smartcard/${id}/product`}
                          className={`custom-button`}
                          onClick={()=>handleShowHighlights(true)}
                        >
                          Products / Service
                        </NavLink>
                        <NavLink to={`/smartcard/${id}/service`} className={`custom-button`}
                          onClick={()=>handleShowHighlights(false)}>
                          Service Request
                        </NavLink>
                        <NavLink
                          to={`/smartcard/${id}/rate-review`}
                          className={`custom-button`}
                          onClick={()=>handleShowHighlights(false)}
                        >
                          Ratings & Reviews
                        </NavLink>
                      </>
                    )
                  }


                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={showHighlights ? 6 : 9} lg={showHighlights ? 6 : 9} xl={showHighlights ? 6 : 9}>
              <Outlet></Outlet>
            </Grid>
            {
              showHighlights === true && (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="profile-box1">
                    <div>
                      <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                      <ul className="p-0">
                        <li className="d-flex align-items-start justify-content-start">
                          <div className="key-img mt-2">
                            <img src={"/images/pie-chart.png"} alt="pieChart" />
                          </div>
                          <div className="ml-3">
                            <>
                              <span className="list-span">Specialized in Sector</span>
                            </>
                            <>
                              <p className="list-para ">{formData.sector?formData.sector:'NA'}</p>
                            </>
                          </div>
                        </li>
                        {/* <li className="d-flex align-items-start justify-content-start">
                  <div className="key-img mt-2">
                    <img src={"/images/grow-up.png"} alt="pieChart" />
                  </div>
                  <div className="ml-3">
                    <>
                      <span className="list-span">Product / Service</span>
                    </>
                    <>
                      <p className="list-para mb-0">E- Varuvaai </p>
                      <p className="list-para">E- Mugavari</p>
                    </>
                  </div>
                </li>

                <li className="d-flex align-items-start justify-content-start">
                  <div className="key-img mt-2">
                    <img src={"/images/star.png"} alt="pieChart" />
                  </div>
                  <div className="ml-3">
                    <>
                      <span className="list-span">Ratings</span>
                    </>
                    <>
                      <p className="list-para">
                        <span className="mr-2">
                          <img
                            src={"/images/star-rate.png"}
                            alt="pieChart"
                          />
                        </span>
                        4.2 / 5 (10)
                      </p>
                    </>
                  </div>
                </li> */}
                      </ul>
                    </div>
                  </div>
                </Grid>
              )
            }

          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SmartProfileLayout;
