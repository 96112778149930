import React, { useEffect, useState } from 'react'
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

const TnEventSpeakers = () => {
  const [screenSize, setScreenSize] = useState(5);
  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  return (
    <div>
      <div className='video-bg'>
        <div className='startups-fests'>
          <Swiper
            spaceBetween={30}
            slidesPerView={screenSize}
            navigation
            loop
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay, Navigation]}

          >
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/meeting-consider-deliberate-about-meet_svgrepo.com.png" alt="Startup Pitches" />
                <h3>FIRESIDE CHAT</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/network-1_svgrepo.com.png" alt="Cultural Extravaganzas" />
                <h3>NETWORKING SPACES</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group.png" alt="Inaugurations" />
                <h3>BUYER-SELLER MEET</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group (1).png" alt="Immersive Journeys" />
                <h3>STARTUP PITCHES</h3>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/meeting-consider-deliberate-about-meet_svgrepo.com.png" alt="Startup Pitches" />
                <h3>STARTUP PITCHES</h3>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/network-1_svgrepo.com.png" alt="Cultural Extravaganzas" />
                <h3>CULTURAL EXTRAVAGANZAS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group.png" alt="Inaugurations" />
                <h3>INAUGURATIONS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Frame 1707480228.png" alt="Immersive Journeys" />
                <h3>CULTURAL EXTRAVAGANZAS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Frame 1707480228 (1).png" alt="Immersive Journeys" />
                <h3>INAUGURATIONS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Frame 1707480228 (2).png " alt="Immersive Journeys" />
                <h3>IMMERSIVE JOURNEYS</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-item">
                <img src="/images/Banner/Group (1).png" alt="Immersive Journeys" />
                <h3>IMMERSIVE JOURNEYS</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="Speakers-head mt-5">
          <h2>Speakers</h2>
          <h6>Coming Soon..</h6>
        </div>
      </div>
      
      {/* <div className="Speakers-head ">
  <img src="https://startuptn-public-files.s3.ap-south-1.amazonaws.com/Thiruvizha_2024_SpeakersList.gif" alt="Thiruvizha 2024 Speakers List" className='img-fluid'/>
</div> */}
        <img src={'/images/Banner/strip_banner.svg'} alt="strip" className='img-fluid' />
    </div>
  )
}

export default TnEventSpeakers