import { Autocomplete,FormHelperText, Grid, TextField,} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";
import cryptoJS from "../../../../hooks/cryptoJS";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
const BasicinfoMentors = () => {
  const {  navigator, validateEmail, validateNumberonly, yearListApi,yearListValue,setPageLoading, handleNextClick,validatePhoneNumber,
    isWhitespace, validatePANNumber,checkUppernumeric } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit,control,setValue,clearErrors, formState: { errors }, } = useForm({ values: formData });
 
 useDidMountEffect(() => {
    handleNextClick(50);
    yearListApi();
    sectorsdropdownApi();
    designationdropdownApi()
  }, []);

  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result && result.data && result.data.sectors ) {
            var values = result.data.sectors;
            setSectors(values);
        }
      })
      .catch((err) => {});
  };
  const [designationList, setDesignationList] = useState([]);
  const designationdropdownApi = () => {
    apiService("home/designation/list", "", "get")
      .then((result) => {
        if (result && result.data && result.data.designations) {
          var values = result.data.designations;
              setDesignationList(values);
        }
      })
      .catch((err) => {});
  };
  useDidMountEffect(() => {
    if (id !== null) {
      getApi();
    }
  }, [])
  const [selectedValues, setSelectedValues] = useState([]);
  const [designationValues, setDesignationValues] = useState([]);
  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')
  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          if (data.yearOfExperience === 0) {
            data.yearOfExperience = ''
          }     
         
          if (data.email) {
            data.email = cryptodecryptJS(data.email);
          }
          if (data.phone) {
            data.phone = cryptodecryptJS(data.phone);
          }
          if (data.pan) {
            data.pan = cryptodecryptJS(data.pan)
            // setPanTickIcon(1)
          }
          setFormData(data);
          if (data.focusedSector) {
            setSelectedValues(data.focusedSector);
          }
          if (data.designation) {
            setDesignationValues(data.designation);
          }
        }
      })
      .catch((err) => {});
  };
  const handleSelectSector = (value) => {
    setValue("focusedSector", value);
    clearErrors("focusedSector");
    setSelectedValues(value);
  };
  const handleDesignation = (value) => {
    setValue("designation", value);
    clearErrors("designation");
    setDesignationValues(value);
  };
  const onSubmit = (data) => {
    let ids = [];
    data?.focusedSector?.forEach((name) => {
      sectors?.forEach((val) => {
        if (name === val?.name) {
          ids?.push({ name, id: val.id.toString() });
        }
      });
    });
    const getIds = ids.map((val) => val.id);
       data.persona = type
    data.userid = id
    data.type = localStorage.getItem("role");
    data.phone = cryptoJS(data.phone);
    data.email = cryptoJS(data.email.toLowerCase());
    data.pan = cryptoJS(data.pan.toUpperCase());
    const payload = {
      ...data,
      focusedSectorIds: getIds,
    };
 
    
    apiService("userprofile/save", payload, "post")
      .then((result) => {
        if (result && result.data && result.data.responseStatus === "Success") {
          navigator("/mentors/address");
        }
      })
      .catch((err) => {});
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService("danger", "Submit Failed", "Please check the mandatory fields" );
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">Basic Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">1</span>/4
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Full Name is required",
                        validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                      },
                       }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Full Name *"
                          placeholder="Enter Full Name"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.name && errors.name)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.name && errors.name.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Mail ID is required",
                        validate: validateEmail,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Mail ID *"
                          placeholder="Mail ID"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email && errors.email)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        validate: validatePhoneNumber,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Phone Number *"
                          placeholder="Enter Phone Number"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.phone && errors.phone)}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.phone && errors.phone.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="organisationName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Organization Name is required" ,
                        validate: {
                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                      },
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Organization Name *"
                          placeholder="Enter Organization Name"
                          fullWidth
                          error={Boolean(errors.organisationName && errors.organisationName)}
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.organisationName &&
                        errors.organisationName.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    {/* <Controller
                      name="designation"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Designation is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Designation *"
                          placeholder="Enter Designation"
                          fullWidth
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.designation && errors.designation.message}
                    </FormHelperText> */}
                    <Controller
                      name="designation"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Designation is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          // multiple={true}
                          {...field}
                          disableClearable
                          options={designationList.map((list) => list.name)}
                          // getOptionLabel={(option) => option}
                          // defaultValue={[]}
                          // value={value || []}
                          value={designationValues}
                          onChange={(e, selectedOption) =>
                            handleDesignation(selectedOption)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Designation *"
                              placeholder="Select Designation"
                              error={Boolean(errors.designation && errors.designation)}
                              // helperText={error ? "Designation is required" : ""}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger ml-0">
                      {errors.designation && errors.designation.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="focusedSector"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          disableClearable
                          options={sectors.map((list) => list.name)}
                          value={selectedValues}
                          onChange={(e, selectedOption) =>
                            handleSelectSector(selectedOption)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Focused Sector *"
                              placeholder="Select Focused Sector"
                              error={!!error}
                              helperText={error ? "Focused Sector is required" : ""}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.focusedSector && errors.focusedSector.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="yearOfExperience"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          options={yearListValue}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          disableClearable
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Years of Experience *"
                              placeholder="Seletct Years of Experience"
                              error={!!error}
                              helperText={
                                error ? "Years of Experience is required" : ""
                              }
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.yearOfExperience &&
                        errors.yearOfExperience.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="pan"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "PAN No. is required",
                        // validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN No. *"
                          placeholder="Enter PAN No."
                          fullWidth
                          type="text"
                          // inputProps={{ maxLength: 10 }}
                          // onKeyDown={(e) => checkUppernumeric(e)}
                          // onInput={(e) => {
                          //   handlePanChange(e);
                          // }}
                          {...field}
                          error={Boolean(errors.pan && errors.pan)}
                        // InputProps={{
                        //   endAdornment: panTickIcon === 1 ? (
                        //     <InputAdornment position="end">
                        //       <CheckCircleIcon color="success" />
                        //     </InputAdornment>
                        //   ) : panTickIcon === 2 ? (
                        //     <InputAdornment position="end">
                        //       <CloseIcon color="error" />
                        //     </InputAdornment>
                        //   ) : null,
                        // }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pan && errors.pan.message}
                    </FormHelperText>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="text-center"
                  >
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicinfoMentors;
