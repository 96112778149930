import { Autocomplete, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Link } from "react-router-dom";
import apiService from "../../../../api/apiService";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";

const MoreinfoEcosystem = () => {
  const { navigator, yearListApi, yearListValue, setPageLoading, handleNextClick, isWhitespace, fastUrlPath } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, watch, getValues, clearErrors, formState: { errors } } = useForm({ values: formData });

  useDidMountEffect(() => {
    {
      roleval === "MEDIA-AGENCY" ||
        roleval === "BLOGGER-INFLUENCER" ||
        roleval === "NGO"
        ? handleNextClick(85)
        : handleNextClick(98);
    }

    getApi();
    yearListApi();
    supportdropdownApi();
    sectorsdropdownApi();
    entitiesdropdownApi();
    stagesdropdownApi();
    industriesdropdownApi();
  }, []);
  const [support, setSupport] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [entity, setEntity] = useState([]);
  const [stages, setStages] = useState([]);
  const [industry, setIndustry] = useState([]);

  const supportdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"support"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setSupport(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data.sectors;
              setSectors(values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const entitiesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"entities"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setEntity(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const stagesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"stages"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setStages(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const industriesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"industries"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setIndustry(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const onError = (event) => {
    console.log("error Data:", event);
  };
  const roleval = localStorage.getItem("role");

  const eduIns = watch("eduIns", false);
  const handleFilter2 = (event) => {
    const iseduIns = event.target.value === "true";
    setValue("eduIns", iseduIns);

    if (!iseduIns) {
      setValue("eduIns", "");
    }
  };

  const focusingBranches = watch("focusingBranches", false);
  const handleFilter = (event) => {
    const isfocusingBranches = event.target.value === "true";
    setValue("focusingBranches", isfocusingBranches);

    if (!isfocusingBranches) {
      setValue("focusingBranches", "");
    }
  };

  const problemStatementCheck = watch("problemStatementCheck", false);
  const handleFilter1 = (event) => {
    const isproblemStatementCheck = event.target.value === "true";
    setValue("problemStatementCheck", isproblemStatementCheck);

    if (!isproblemStatementCheck) {
      setValue("problemStatementCheck", "");
    }
  };

  const { fields: benefitsFields, append: benefitsAppend, remove: benefitsRemove, } = useFieldArray({ control, name: "problemStatements" });
  const addBenefitsDetailsBtn = () => {
    benefitsAppend({
      id: 0,
      statement: "",
    });
  };
  const removeBenefitsDetailsBtn = (index) => {
    var id = getValues(`problemStatements.${index}.id`);

    if (id !== 0) {

      apiService(`user/remove/statement?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          benefitsRemove(index);
          getApi()
        }
      }).catch((err) => {
      });
    } else {
      benefitsRemove(index);
    }
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const getApi = () => {
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data
          setFormData(data)
          if(data.yearOfReg === 0){
             data.yearOfReg = ''
          }

          if (data.focusedSector) {
            setSelectedValues(data.focusedSector)
          }

        }

      }).catch((err) => {

      });
    // if (roleval === "INCUBATOR" || roleval === "ACCELARATOR") {
    //   apiService(`incubacc/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;

    //         // setSectorsValues(data.sector);
    //         setFormData(data);
    //         if (data.sector) {
    //           setSelectedValues(data.sector)
    //         }

    //       }
    //     })
    //     .catch((err) => { });
    // } else if (
    //   roleval === "MEDIA-AGENCY" ||
    //   roleval === "BLOGGER-INFLUENCER" ||
    //   roleval === "NGO"
    // ) {
    //   apiService(`mediablogngo/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;
    //         setFormData(data);
    //       }
    //     })
    //     .catch((err) => { });
    // } else if (roleval === "GOVT-AGENCY") {
    //   apiService(`govermentagencies/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;
    //         setFormData(data);
    //       }
    //     })
    //     .catch((err) => { });
    // } else if (roleval === "BANK") {
    //   apiService(`bank/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;
    //         setFormData(data);
    //       }
    //     })
    //     .catch((err) => { });
    // } else if (roleval === "CORPORATE") {
    //   apiService(`corporates/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;
    //         setFormData(data);
    //       }
    //     })
    //     .catch((err) => { });
    // } else if (roleval === "BUSINESS-SOCIAL-FORM") {
    //   apiService(`busssocial/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;
    //         setFormData(data);
    //       }
    //     })
    //     .catch((err) => { });
    // } else if (roleval === "EDU-INST") {
    //   apiService(`eduinstitute/get?id=${id}`, "", "get")
    //     .then((result) => {
    //       if (result && result.data) {
    //         var data = result.data;
    //         setFormData(data);
    //       }
    //     })
    //     .catch((err) => { });
    // }
  };

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    let ids = [];

    data?.focusedSector?.forEach((name) => {
      sectors?.forEach((val) => {
        if (name === val?.name) {
          ids?.push({ name, id: val.id.toString() });
        }
      });
    });
    let totalIncubatees = parseInt(data.totalIncubatees);
    data.totalIncubatees = totalIncubatees;
    let yearOfReg = parseInt(data.yearOfReg);
    data.yearOfReg = yearOfReg;
    let totalStartups = parseInt(data.totalStartups);
    data.totalStartups = totalStartups;
    let year = parseInt(data.yearOfReg);
    data.yearOfReg = year;
    console.log(data.profileUpdated, "ProfileUpdated")
    debugger
    if (
      roleval === "MEDIA-AGENCY" ||
      roleval === "BLOGGER-INFLUENCER" ||
      roleval === "NGO"
    ) {

    } else {
      data.profileUpdated = true
    }

    const getIds = ids.map((val) => val.id)
    const payload = {
      ...data, focusedSectorIds: getIds
    }

    console.log(payload, "payload")

    // let url;
    // if (roleval === "INCUBATOR" || roleval === "ACCELARATOR") {
    //   url = "userprofile/save";
    //   data.profileUpdated = true
    // } else if (
    //   roleval === "MEDIA-AGENCY" ||
    //   roleval === "BLOGGER-INFLUENCER" ||
    //   roleval === "NGO"
    // ) {
    //   url = "userprofile/save";
    //   // navigator("/ecosystem/founder");
    // } else if (roleval === "GOVT-AGENCY") {
    //   url = "userprofile/save";
    //   data.profileUpdated = true
    // } else if (roleval === "BANK") {
    //   url = "userprofile/save";
    //   data.profileUpdated = true
    // } else if (roleval === "CORPORATE") {
    //   url = "userprofile/save";
    //   data.profileUpdated = true
    // } else if (roleval === "BUSINESS-SOCIAL-FORM") {
    //   url = "userprofile/save";
    //   data.profileUpdated = true
    // } else if (roleval === "EDU-INST") {
    //   url = "userprofile/save";
    //   data.profileUpdated = true
    // }


    apiService('userprofile/save', payload, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          localStorage.setItem("id", result.data.id);
          if (
            roleval === "MEDIA-AGENCY" ||
            roleval === "BLOGGER-INFLUENCER" ||
            roleval === "NGO"
          ) {
            navigator("/ecosystem/founder");
          } else {
            localStorage.setItem('profile', true)
            localStorage.setItem('email', data.email)
            const currentPath = localStorage.getItem('currentPath');
            if (currentPath) {
              fastUrlPath()
            } else {
              navigator('/ecosystem/profile')
            }
          }
        }
      })
      .catch((err) => { });
  };


  const handleSelectSector = (value) => {

    setValue("focusedSector", value);
    clearErrors('focusedSector');
    setSelectedValues(value);

  };
  // const handleSelectSector = (id, name) => {
  //   setSelectedValues(name);
  //   setSelectOption(id);

  //   // userlistApi()
  // };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">More Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">4</span>/4
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {roleval === "INCUBATOR" || roleval === "ACCELARATOR" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        {/* <Controller
                          name="sector"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                            multiple={true}
                              disablePortal
                              id="combo-box-demo"
                              options={sectors}
                              value={selectOption}
                              getOptionLabel={(option) => option.name}
                              onChange={(e, selectedOption) =>
                                handleSelectSector(
                                 selectedOption
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  value={field.value}
                                  {...params}
                                  label="Focus Sector"
                                  placeholder="Select Sector"
                                  error={!!error}
                                  helperText={
                                    error ? "This field is required" : ""
                                  }
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        /> */}
                        <Controller name="focusedSector" control={control} defaultValue="" rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              disableClearable
                              {...field}
                              multiple
                              options={sectors.map((list) => list.name)}
                              // getOptionLabel={(option) => option.name}
                              // defaultValue={[]}
                              value={selectedValues}
                              onChange={(e, selectedOption) =>
                                handleSelectSector(selectedOption)
                              }
                              renderInput={(params) => (
                                <TextField
                                  // value={selectedValues}
                                  {...params}
                                  label=" Focus Sector *"
                                  placeholder="Select Focus  Sector"
                                  error={Boolean(errors.focusedSector && errors.focusedSector)}
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.focusedSector && "Select Focus is required"}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="preferredStage"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              disableClearable
                              multiple={true}
                              options={stages}
                              getOptionLabel={(option) => option}
                              defaultValue={[]}
                              value={value || []}
                              onChange={(event, newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Preferred Stage Of Startups *"
                                  placeholder="Select Preferred Stage Of Startups"
                                  error={!!error}
                                  helperText={error ? "Select is required" : ""}
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.preferredStage &&
                            errors.preferredStage.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="natureOfEntity"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              disableClearable
                              options={entity}
                              getOptionLabel={(option) => option}
                              defaultValue={[]}
                              value={value || []}
                              onChange={(event, newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Nature of Entity *"
                                  placeholder="Select Nature of Entity"
                                  error={!!error}
                                  helperText={
                                    error ? "Nature of Entity is required" : ""
                                  }
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.natureOfEntity &&
                            errors.natureOfEntity.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="yearOfReg"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              disableClearable
                              options={yearListValue}
                              getOptionLabel={(option) => option}
                              defaultValue={[]}
                              value={value || []}
                              onChange={(event, newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Year Of Registration *"
                                  placeholder="Select Year Of Registration"
                                  error={Boolean(errors.yearOfReg &&
                                    errors.yearOfReg)}

                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.yearOfReg &&
                            errors.yearOfReg.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <div>
                          <Controller
                            name="organizationInfo"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Organization Info is required",}}
                            rules={{
                              required: 'Organization Info is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                id="outlined-multiline-static"
                                label="Organization Info*"
                                fullWidth
                                {...field}
                                defaultValue=""
                                error={Boolean(
                                  errors.organizationInfo && errors.organizationInfo
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.organizationInfo && errors.organizationInfo.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <div>
                          <Controller
                            name="supportStartups"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Required" }}
                            rules={{
                              required: 'Required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                id="outlined-multiline-static"
                                label="What all support do you offer to startups?"
                                fullWidth
                                {...field}
                                defaultValue=""
                                error={Boolean(
                                  errors.supportStartups && errors.supportStartups
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.supportStartups && errors.supportStartups.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "INCUBATOR" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <div>
                          <Controller
                            name="totalIncubatees"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "No Of Incubatees is required" }}
                            rules={{
                              required: 'No Of Incubatees is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                id="outlined-multiline-static"
                                label="Total Number of incubatees so far*"
                                fullWidth
                                {...field}
                                defaultValue=""
                                error={Boolean(
                                  errors.totalIncubatees &&
                                  errors.totalIncubatees
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.totalIncubatees && errors.totalIncubatees.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>Government funded</p>
                        <Controller
                          name="governmentFunded"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.governmentFunded && errors.governmentFunded.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>
                          Are you a part of an educational institution? If yes
                          please mention
                        </p>
                        <Controller
                          name="eduIns"
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row value={eduIns ? "true" : "false"} onChange={handleFilter2} >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.eduIns && errors.eduIns.message}
                        </FormHelperText>
                      </Grid>
                      {eduIns && (
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                          <div>
                            <Controller
                              name="eduInsName"
                              control={control}
                              defaultValue=""
                              // rules={{ required: "Educational Institution is required", }}
                              rules={{
                                required: 'Educational Institution is required', validate: {
                                  noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Educational Institution*"
                                  fullWidth
                                  {...field}
                                  defaultValue=""
                                  error={Boolean(
                                    errors.eduInsName && errors.eduInsName
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.eduInsName && errors.eduInsName.message}
                            </FormHelperText>
                          </div>
                        </Grid>
                      )}
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>Do you invest in startups?</p>
                        <Controller
                          name="investStartups"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.investStartups && errors.investStartups.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>Available Facilities</p>
                        <Controller
                          name="available"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.available && errors.available.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <div>
                          <Controller
                            name="scheme"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Schemes Available is required",}}
                            rules={{
                              required: 'Schemes Available is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="outlined-multiline-static"
                                label="Schemes Available*"
                                fullWidth
                                multiline
                                rows={4}
                                defaultValue=""
                                error={Boolean(errors.scheme && errors.scheme)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.scheme && errors.scheme.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <div>
                          <Controller
                            name="specialRecg"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "Special recognition is required", }}
                            rules={{
                              required: 'Special recognition is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="outlined-multiline-static"
                                label="Special recognition * ?"
                                fullWidth
                                multiline
                                rows={4}
                                defaultValue=""
                                error={Boolean(
                                  errors.specialRecg && errors.specialRecg
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.specialRecg && errors.specialRecg.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "ACCELARATOR" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <div>
                          <Controller
                            name="totalStartups"
                            control={control}
                            defaultValue=""
                            // rules={{ required: "No Of Portfolio is required" }}
                            rules={{
                              required: 'No Of Portfolio is required', validate: {
                                noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              },
                            }}
                            render={({ field }) => (
                              <TextField
                                id="outlined-multiline-static"
                                label="Total Number of Portfolio startups so far *"
                                fullWidth
                                {...field}
                                defaultValue=""
                                error={Boolean(
                                  errors.totalStartups && errors.totalStartups
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.totalStartups && errors.totalStartups.message}
                          </FormHelperText>
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <p>Do you invest in startups?</p>
                        <Controller
                          name="investStartups"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl
                              component="fieldset"
                              className="d-flex"
                            >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.investStartups && errors.investStartups.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "EDU-INST" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>
                          Do you want to partner with StartupTN for knowledge
                          events?
                        </p>
                        <Controller
                          name="partnerWithStartuptn"
                          control={control}
                          rules={{ required: false }}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.partnerWithStartuptn && errors.partnerWithStartuptn.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>Does your college have an incubation cell?</p>
                        <Controller
                          name="incubationCell"
                          control={control}
                          rules={{ required: false }}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.incubationCell && errors.incubationCell.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>
                          Do you want us to support you with setting up an incubation cell?
                        </p>
                        <Controller
                          name="support"
                          control={control}
                          rules={{ required: false }}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.support && errors.support.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>
                          Are you willing to function your alumni network as an angel network?
                        </p>
                        <Controller
                          name="alumini"
                          control={control}
                          rules={{ required: false }}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              component="fieldset"
                              className="d-flex"
                            >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.alumini && errors.alumini.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "BUSINESS-SOCIAL-FORM" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="yearOfReg"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              disableClearable
                              options={yearListValue}
                              getOptionLabel={(option) => option}
                              defaultValue={[]}
                              value={value || []}
                              onChange={(event, newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Year Of Registeration"
                                  placeholder="Select Year Of Registeration"
                                  error={!!error}
                                  helperText={
                                    error
                                      ? "Year Of Registeration is required"
                                      : ""
                                  }
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.yearOfReg && errors.yearOfReg.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="purpose"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Purpose of the Forum is required"}}
                          rules={{
                            required: 'Purpose of the Forum is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-multiline-static"
                              label="Purpose of the Forum*"
                              fullWidth
                              multiline
                              rows={3}
                              defaultValue=""
                              error={Boolean(errors.purpose && errors.purpose)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.purpose && errors.purpose.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="totalMembers"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Total members in your forum is required",}}
                          rules={{
                            required: 'Total members in your forum is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Total members in your forum*"
                              placeholder="Enter Total members in your forum"
                              fullWidth
                              type="number"
                              {...field}
                              error={Boolean(
                                errors.totalMembers && errors.totalMembers
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.totalMembers && errors.totalMembers.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="expectationFromStartuptn"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Enter your expectation from StartupTN is required",}}
                          rules={{
                            required: 'Enter your expectation from StartupTN is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="What is your expectation from StartupTN?*"
                              placeholder="Enter your expectation from StartupTN"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(
                                errors.expectationFromStartuptn &&
                                errors.expectationFromStartuptn
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.expectationFromStartuptn && errors.expectationFromStartuptn.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>Are you willing to partner with StartupTN?</p>
                        <Controller
                          name="partnerWithStartuptn"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl
                              component="fieldset"
                              className="d-flex"
                            >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.partnerWithStartuptn && errors.partnerWithStartuptn.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "CORPORATE" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="industry"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              disableClearable
                              // multiple={true}
                              options={industry}
                              getOptionLabel={(option) => option}
                              defaultValue={[]}
                              value={value || []}
                              onChange={(event, newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Industry"
                                  placeholder="Select Industry "
                                  error={!!error}
                                  helperText={
                                    error ? "Industry is required" : ""
                                  }
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.industry && errors.industry.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="corporatePrograms"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Do you conduct corporate Accelerator programs?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.corporatePrograms && errors.corporatePrograms.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="hireInternsOrFte"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Do you want to hire interns/FTE for your company
                                through StartupTN?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field} >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.hireInternsOrFte && errors.hireInternsOrFte.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="interestToConductS2c"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Are you interested to conduct a S2C with
                                StartupTN?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.interestToConductS2c && errors.interestToConductS2c.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="interestToConductOinn"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Are you interested to conduct open innovation challenges?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.interestToConductOinn && errors.interestToConductOinn.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="initiatives"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Do you have inititaives which can support Startup Ecosystem?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field} >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.initiatives && errors.initiatives.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="csrFund"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Do you have a CSR fund?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.csrFund && errors.csrFund.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "GOVT-AGENCY" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="interestToConductS2g"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Are you interested to conduct a S2G with StartupTN?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.interestToConductS2g && errors.interestToConductS2g.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="problemStatementCheck"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex">
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                If yes, do you have defined problem statementsplease list out the same
                              </FormLabel>
                              <RadioGroup {...field} row value={problemStatementCheck ? "true" : "false"} onChange={handleFilter1}>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.problemStatementCheck && errors.problemStatementCheck.message}
                        </FormHelperText>
                      </Grid>
                      {problemStatementCheck && (
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                          <div className="row">
                            <div className="col-lg-10 col-md-8 col-10 mar-top ">
                              <h5 className="point_Lable">
                                Please list out the some{" "}
                                <span className="c-red">*</span>{" "}
                              </h5>
                            </div>
                            <div className="col-lg-2 col-md-4 col-2 mar-top">
                              <Button
                                className="green_btn"
                                onClick={addBenefitsDetailsBtn}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                          {benefitsFields.length > 0 && benefitsFields.map((field, index, type) => (
                            <div className="col-lg-12 col-md-12 col-12 mb-3" key={index} >
                              <div className="row align-item-center">
                                <div className="col-lg-10 col-md-8 col-12 p-0">
                                  <div className="form-groups">
                                    <Controller
                                      // name={`benefits.${index}.problemStatement`}
                                      name={`problemStatements.${index}.statement`}
                                      control={control}
                                      defaultValue=""
                                      // rules={{ required: "Please list out the some" }}
                                      rules={{
                                        required: 'Please list out the some', validate: {
                                          noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                        },
                                      }}
                                      render={({ field }) => (
                                        <TextField
                                          id="outlined-multiline-static"
                                          label="List of statements* "
                                          fullWidth
                                          {...field}
                                          defaultValue=""
                                          error={Boolean(errors.problemStatements?.[index]?.statement && errors.problemStatements?.[index]?.statement)}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.problemStatements?.[index]?.statement && errors.problemStatements?.[index]?.statement.message}
                                    </FormHelperText>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-12 mar-top">
                                  {index !== 0 && (
                                    <Button
                                      className="red_btn"
                                      onClick={() =>
                                        removeBenefitsDetailsBtn(index)
                                      }
                                    >
                                      <DeleteForeverIcon />
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </Grid>
                      )}
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="startuptnToSensitise"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Do you want StartupTN to sensitise about startups to your Organization?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field} >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.startuptnToSensitise && errors.startuptnToSensitise.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="schemes"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Do you have schemes which might benefit startups?
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field} >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.schemes && errors.schemes.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="rfporeoi"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Have you floated RFP/EOI with startup exemption clause as per{" "}
                                <Link style={{ color: "#0064ff" }}>
                                  {" "}
                                  G.O. Ms. 40, Small and Medium Enterprises Department dated , 20.07.2021
                                </Link>
                              </FormLabel>
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" {...field}>
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.rfporeoi && errors.rfporeoi.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "BANK" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>
                          Do you have inititaives which can support Startup Ecosystem?
                        </p>
                        <Controller
                          name="initiatives"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex" >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.initiatives && errors.initiatives.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <p>Do you want to partner with StartupTN?</p>
                        <Controller
                          name="partnerWithStartuptn"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex">
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.partnerWithStartuptn && errors.partnerWithStartuptn.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>Do you provide soft loans to Startups?</p>
                        <Controller
                          name="softLoans"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex"
                            >
                              <RadioGroup {...field} row>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.softLoans && errors.softLoans.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <p>
                          Do you have any Startup focusing branches in TamilNadu? If yes, please mention
                        </p>
                        <Controller
                          name="focusingBranches"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <FormControl component="fieldset" className="d-flex">
                              {/* <RadioGroup {...field} row onChange={handleRadioChange1}> */}
                              <RadioGroup {...field} row value={focusingBranches ? "true" : "false"} onChange={handleFilter} >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.focusingBranches && errors.focusingBranches.message}
                        </FormHelperText>
                      </Grid>
                      {focusingBranches === true && (
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                          <div>
                            <Controller
                              name="focusingBranchesName"
                              control={control}
                              defaultValue=""
                              // rules={{ required:"Startup focusing branches is required",}}
                              rules={{
                                required: 'Startup focusing branches is required', validate: {
                                  noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Do you have any Startup focusing branches in Tamil Nadu?"
                                  fullWidth
                                  {...field}
                                  defaultValue=""
                                  error={Boolean(
                                    errors.focusingBranchesName && errors.focusingBranchesName
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.focusingBranchesName && errors.focusingBranchesName.message}
                            </FormHelperText>
                          </div>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "MEDIA-AGENCY" ||
                    roleval === "BLOGGER-INFLUENCER" ||
                    roleval === "NGO" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="organizationBrief"
                          control={control}
                          defaultValue=""
                          // rules={{ required:" Brief/About the Organization is required",}}
                          rules={{
                            required: 'Brief/About the Organization is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-multiline-static"
                              label=" Brief/About the Organization*"
                              fullWidth
                              multiline
                              rows={3}
                              defaultValue=""
                              error={Boolean(
                                errors.organizationBrief &&
                                errors.organizationBrief
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.organizationBrief && errors.organizationBrief.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "MEDIA-AGENCY" ||
                    roleval === "BLOGGER-INFLUENCER" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="describeService"
                          control={control}
                          defaultValue=""
                          // rules={{ required:"Describe the Service you offer is required",}}
                          rules={{
                            required: 'Describe the Service you offer is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-multiline-static"
                              label="Describe the Service you offer*"
                              fullWidth
                              multiline
                              rows={3}
                              defaultValue=""
                              error={Boolean(
                                errors.describeService && errors.describeService
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.describeService && errors.describeService.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {roleval === "NGO" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3" >
                        <Controller
                          name="mission"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "What is your mission is required",}}
                          rules={{
                            required: 'What is your mission is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="outlined-multiline-static"
                              label="What is your mission?*"
                              fullWidth
                              multiline
                              rows={3}
                              defaultValue=""
                              error={Boolean(errors.mission && errors.mission)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.mission && errors.mission.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                    <button className="next-btn" type="submit">
                      {roleval === "MEDIA-AGENCY" ||
                        roleval === "BLOGGER-INFLUENCER" ||
                        roleval === "NGO"
                        ? `Next`
                        : "Save"}
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MoreinfoEcosystem;
