import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";

const SmartRateReviewPage = () => {
  const [value, setValue] = useState(4);
  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className="investment-whole-div">
            <div className="profile-box2 mt-2">
              <h5 style={{ fontWeight: "600" }} className="mb-3">Ratings & Reviews</h5>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <h5 className="color-blue"><img src={"/images/star-rate.png"} /> 4.2 / 5 (10)</h5>
                <div className="my-3">
                  <div className="review-details">
                    <div className="mr-2">
                      <img src="/images/ecopic.png" className="review_logo" alt="" />
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <h6>Lorem Ipsum </h6>
                        <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} /> 4.2</p>
                      </div>
                      <p className="mb-0 paras">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                  </div>
                  <div className="review-details">
                    <div className="mr-2">
                      <img src="/images/ecopic.png" className="review_logo" alt="" />
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <h6>Lorem Ipsum </h6>
                        <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} /> 4.2</p>
                      </div>
                      <p className="mb-0 paras">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="set-ratingbox">
            <div>
              <h6 className="avg-rating mb-3">Average Rating</h6>
              <div className="avg-rate-div">
                <h1 >
                  4.0
                  <span className="ml-2">
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </span>
                </h1>
                <p className="para-smcrd-rate">Average Rating on this year</p>
              </div>
              <div>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <div className="prog-flexs">
                    <span>5</span>
                    <LinearProgress
                      variant="determinate"
                      value={80}
                      color="success"
                      sx={{ width: `${80}%` ,mx:1}}
                    />
                    <span>10</span>
                  </div>
                  <div className="prog-flexs">
                    <span>4</span>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      color="error"
                      sx={{ width: `${60}%`,mx:1 }}
                    />
                    <span>8</span>
                  </div>
                  <div className="prog-flexs">
                    <span>3</span>
                    <LinearProgress
                      variant="determinate"
                      value={40}
                      color="warning"
                      sx={{ width: `${40}%`,mx:1 }}
                    />
                    <span>5</span>
                  </div>
                  <div className="prog-flexs">
                    <span>2</span>
                    <LinearProgress
                      variant="determinate"
                      value={20}
                      color="info"
                      sx={{ width: `${20}%`,mx:1 }}
                    />
                    <span>2</span>
                  </div>
                  <div className="prog-flexs">
                    <span>1</span>
                    <LinearProgress
                      variant="determinate"
                      value={0}
                      color="error"
                      sx={{ width: `${0}%`,mx:1 }}
                    />
                    <span>0</span>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmartRateReviewPage;
